//This file holds the API calls that hit the /user route for DRY purposes
import { CancelTokenSource } from 'axios';

import {
  ChartFilterElements,
  ChartFilterElementsForProviderSummary
} from '../../interfaces/charts';
import { DisputeStatus, Hop } from '../../interfaces/hop';
import { Pagination } from '../../interfaces/pagination';
import { StirShakenRequest } from '../../interfaces/stirShaken';
import { FilterElement } from '../../lib/FilterElement';
import { ApiRequest, buildParams, withCancelRequestFunctionality } from '../../lib/apiRequest';

const getHopsWithCancelApiCalls = withCancelRequestFunctionality();

export const getAllHopsApiCall = async (params: Pagination, filterElements: FilterElement) =>
  getHopsWithCancelApiCalls((cancelToken: CancelTokenSource) =>
    ApiRequest.request({
      method: 'POST',
      url:
        `/hops/search?` +
        buildParams({
          ...params
        }),
      data: filterElements,
      cancelToken: cancelToken.token
    })
  );

export const getProviderInSequenceStatusApiCall = async (
  tracebackId: number,
  selectedProviderId: number
) =>
  ApiRequest.request({
    method: 'POST',
    url: `/hops/checkPreviousHopsForProviderId`,
    data: {
      tracebackId,
      selectedProviderId
    }
  });

export const getHopApiCall = async (hopId: number) =>
  ApiRequest.request({
    method: 'GET',
    url: `/hops/${hopId}`
  });

export const getHopStatusApiCall = async () =>
  ApiRequest.request({
    method: 'GET',
    url: '/AppInfo/Enums/HopStatusCodes'
  });

export const updateHopApiCall = async (content: Hop) =>
  await ApiRequest.request({
    method: 'PUT',
    url: `/hops/${content.hopId}`,
    data: { ...content }
  });

export const deleteHopApiCall = async (hopId: number) =>
  ApiRequest.request({
    method: 'DELETE',
    url: `/hops/${hopId}`
  });

export const resetHopApiCall = async (hopId: number) =>
  ApiRequest.request({
    method: 'PUT',
    url: `/hops/${hopId}/unlock`
  });

export const getHopCommentsApiCall = async (hopId: number) =>
  ApiRequest.request({
    method: 'GET',
    url: `/hops/${hopId}/comments`
  });

export const getHopsCountApiCall = async (filterElements: FilterElement) =>
  ApiRequest.request({
    method: 'POST',
    url: `hops/count`,
    data: filterElements
  });

export const getOriginStatsApiCall = async (
  filterElements: ChartFilterElementsForProviderSummary
) =>
  ApiRequest.request({
    method: 'POST',
    url: `/hops/originstats`,
    data: filterElements
  });

export const getHopsOrProvidersCountriesApiCall = async (
  filterElements: ChartFilterElements,
  onlyCaller?: boolean
) =>
  ApiRequest.request({
    method: 'POST',
    url: `/providers/countries`,
    data: { ...filterElements, onlyCaller }
  });

export const getCSVApiCall = async (filterElements: FilterElement, params: Pagination) =>
  ApiRequest.request({
    method: 'POST',
    url:
      `/download-provider-traceback-csv?` +
      buildParams({
        ...params
      }),
    data: filterElements,
    responseType: 'blob',
    headers: { accept: 'text/csv' }
  });

export const updateStirShakenForHopApiCall = async (hopId: number, stirShaken: StirShakenRequest) =>
  ApiRequest.request({
    method: 'POST',
    url: `stir_shaken/hop/${hopId}`,
    data: stirShaken
  });

export const deleteStirShakenForHopApiCall = async (hopId: number) =>
  ApiRequest.request({
    method: 'DELETE',
    url: `stir_shaken/hop/${hopId}`
  });

export const getHopDisputeApiCall = async (hopId: number) =>
  ApiRequest.request({
    method: 'GET',
    url: `/dispute/${hopId}`
  });

export const resolveHopDisputeApiCall = async (
  hopId: number,
  status: DisputeStatus,
  message: string
) =>
  ApiRequest.request({
    method: 'POST',
    url: `/dispute/resolve`,
    data: { hopId, status, message }
  });

export const setIsCallSignerForStirShakenApiCall = async (hopId: number) =>
  ApiRequest.request({
    method: 'GET',
    url: `stir_shaken/setIsCallSigner/${hopId}`
  });

export const explainNRUpstreamHop = async (
  hopId: number,
  actionTaken: string,
  explanation?: string
) =>
  ApiRequest.request({
    method: 'POST',
    url: `/hops/explain/${hopId}`,
    data: { hopId, actionTaken, explanation }
  });

export const getProviderIdForEmail = async (email: string) =>
  ApiRequest.request({
    method: 'POST',
    url: `/hops/getProviderIdForEmail`,
    data: { email }
  });
