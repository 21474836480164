import React, { FC, Fragment, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { connect } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import './App.scss';
import Default from './components/404';
import AuthRoute from './components/AuthRoute';
import Footer from './components/Footer';
import Layout from './components/Layout';
import SetupForm from './components/SetupForm';
import LoadingIndicator from './components/shared/LoadingIndicator';
import { setupDataManager } from './dataManager/dataManager';
import { userTypes } from './enum/userTypes';
import { Campaign } from './interfaces/campaign';
import { Provider } from './interfaces/provider';
import { PersonalInfo } from './interfaces/user';
import { installCheckVersionHook } from './lib/checkVersionHook';
import About from './pages/About';
import AddEvent from './pages/AddEvent';
import AddRequest from './pages/AddRequest';
import AdminDashboard from './pages/AdminDashboard';
import AddCampaign from './pages/CampaignAdd';
import CampaignDetail from './pages/CampaignDetail';
import Campaigns from './pages/Campaigns';
import CommentAdd from './pages/CommentAdd';
import CommentDetail from './pages/CommentDetail';
import Comments from './pages/Comments';
import CreateNewPassword from './pages/CreateNewPassword';
import DnoProviders from './pages/DnoProviders';
import EditEvent from './pages/EditEvent';
import EmailRedirect from './pages/EmailRedirect';
import GovCommentAdd from './pages/GovCommentAdd';
import HomePage from './pages/HomePage';
import HopDetail from './pages/HopDetail';
import Hops from './pages/Hops';
import ImportTracebacks from './pages/ImportTracebacks';
import Login from './pages/Login';
import Account from './pages/MyAccount';
import NavRequest from './pages/NavRequest';
import Partners from './pages/Partners';
import Privacy from './pages/Privacy';
import AddProvider from './pages/ProviderAdd';
import ProviderDetail from './pages/ProviderDetail';
import ProviderInsight from './pages/ProviderInsight';
import ProviderSummary from './pages/ProviderSummary';
import Providers from './pages/Providers';
import ProvidersInfo from './pages/ProvidersInfo';
import ResetPassword from './pages/ResetPassword';
import SentEmail from './pages/SentEmail';
import Support from './pages/Support';
import TermsOfUse from './pages/TermsOfUse';
import TfHopDetail from './pages/TfHopDetail';
import TfHops from './pages/TfHops';
import AddTraceback from './pages/TracebackAdd';
import TracebackCommentAdd from './pages/TracebackCommentAdd';
import TracebackDetail from './pages/TracebackDetail';
import Tracebacks from './pages/Tracebacks';
import TraceforwardAdd from './pages/TraceforwardAdd';
import TraceforwardDetail from './pages/TraceforwardDetail';
import Traceforwards from './pages/Traceforwards';
import UserDetail from './pages/UserDetail';
import ZendeskLogin from './pages/ZendeskLogin';
import { signOut, staySignedIn } from './redux/auth/thunks';
import { getProviderNameList } from './redux/provider/thunks';
import { stateMappings } from './redux/stateMappings';

interface IProps {
  user: PersonalInfo;
  isAuthenticated: boolean;
  isLoading: boolean;
  setupDataManager: Function;
  staySignedIn: Function;
  signOut: Function;
  campaign: Campaign;
  provider: Provider;
  loadingTree: {};
  getProviderNameList: Function;
}

const App: FC<IProps> = ({
  isAuthenticated,
  signOut,
  staySignedIn,
  setupDataManager,
  isLoading,
  user,
  campaign,
  provider,
  getProviderNameList
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [title, setTitle] = useState('ITG - Portal');
  const [idleTimeoutModalOpen, setIdleTimeoutModalOpen] = useState(false);

  useEffect(() => {
    const { pathname, hash } = location;
    let hop: string[] = [];
    let traceback: string[] = [];
    let tfhop: string[] = [];
    let traceforword: string[] = [];

    if (pathname.startsWith('/tracebacks')) {
      traceback = pathname.split('/');
    }
    if (pathname.startsWith('/hops')) {
      hop = pathname.split('/');
    }
    if (pathname.startsWith('/tfhops')) {
      tfhop = pathname.split('/');
    }
    if (pathname.startsWith('/traceforwards')) {
      traceforword = pathname.split('/');
    }
    if (pathname === '/admin-dashboard') {
      switch (hash) {
        case '#settings':
          setTitle('ITG - Settings');
          break;
        case '#history':
          setTitle('ITG - History');
          break;
        case '#reporting':
          setTitle('ITG - Reporting');
          break;
        case '#users':
          setTitle('ITG - Users');
          break;
        case '#dno':
          setTitle('ITG - Dno');
          break;
        case '#messaging':
          setTitle('ITG - Messages');
          break;
        default:
          if (hash.startsWith('#incidents')) setTitle('ITG - Incidents');
          else setTitle('ITG - Admin');
          break;
      }
    } else {
      switch (true) {
        case traceback.length > 2:
          setTitle('ITG - Traceback - ' + traceback[traceback.length - 1]);
          break;
        case traceback.length === 2:
          setTitle('ITG - Tracebacks');
          break;
        case traceforword.length === 2:
          setTitle('ITG - Traceforwards');
          break;
        case traceforword.length > 2:
          setTitle('ITG - Traceforward - ' + traceforword[traceforword.length - 1]);
          break;
        case hop.length === 2:
          setTitle('ITG - Hops');
          break;
        case hop.length > 2:
          setTitle('ITG - Hop - ' + hop[hop.length - 1]);
          break;
        case tfhop.length === 2:
          setTitle('ITG - TfHops');
          break;
        case tfhop.length > 2:
          setTitle('ITG - TfHop - ' + tfhop[tfhop.length - 1]);
          break;
        case pathname.startsWith('/providers'):
          setTitle('ITG - Providers');
          break;
        case pathname.startsWith('/campaigns'):
          setTitle('ITG - Campaigns');
          break;
        case pathname.startsWith('/comments'):
          setTitle('ITG - Comments');
          break;
        case pathname.startsWith('/account'):
          setTitle('ITG - My Account');
          break;
        case pathname.startsWith('/requests'):
          if (hash === '#result') setTitle('ITG - Request Results');
          else if (hash === '#add') setTitle('ITG - Add Request');
          else setTitle('ITG - Requests');
          break;
        case pathname.startsWith('/partners'):
          setTitle('ITG - Partners');
          break;
        default:
          setTitle('ITG - Portal');
      }
    }
  }, [location]);
  useEffect(() => {
    const { pathname } = location;
    let campaignLocation: string[] = [];
    if (campaign) {
      if (pathname.startsWith('/campaigns')) {
        campaignLocation = pathname.split('/');
        if (
          campaignLocation.length > 2 &&
          Number(campaignLocation[campaignLocation.length - 1]) === campaign.campaignId
        ) {
          setTitle('ITG - Campaign - ' + campaign.name);
        } else setTitle('ITG - Campaigns');
      }
    }
  }, [campaign]);
  useEffect(() => {
    const { pathname } = location;
    let providerLocation: string[] = [];

    if (provider) {
      if (pathname.startsWith('/providers')) {
        providerLocation = pathname.split('/');
        if (
          providerLocation.length > 2 &&
          Number(providerLocation[providerLocation.length - 1]) === provider.providerId
        ) {
          setTitle('ITG - Provider - ' + provider.name);
        } else setTitle('ITG - Providers');
      }
    }
  }, [provider]);
  const idleOut = () => {
    if (isAuthenticated) signOut();
    navigate(
      '/login?errorMessage=' + encodeURIComponent('You have been logged out due to inactivity.')
    );
    setIdleTimeoutModalOpen(false);
  };

  const idleTimer = useIdleTimer({
    element: document,
    onIdle: () => {
      idleOut();
    },
    onActive: () => {
      setIdleTimeoutModalOpen(false);
    },
    onPrompt: () => {
      setIdleTimeoutModalOpen(true);
    },
    startOnMount: false,
    debounce: 250,
    timeout: 15 * 60 * 1e3 + 30 * 1e3,
    crossTab: true,
    syncTimers: 200,
    promptBeforeIdle: 30 * 1e3
  });

  useEffect(() => {
    if (isAuthenticated) {
      setupDataManager(userTypes.Admin === user.roleType);
      staySignedIn();
      idleTimer.reset();
    } else {
      idleTimer.pause();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    if (
      user.roleType === userTypes.DnoUser ||
      user.roleType === userTypes.PartnerUser ||
      !isAuthenticated
    )
      return;
    const timer = setInterval(() => {
      getProviderNameList();
    }, 300000); //5 minutes to refresh the provider names list

    return () => clearInterval(timer);
  }, []);

  const SignOutElement: React.FC = () => {
    signOut();
    return null;
  };

  installCheckVersionHook({ duration: 5 * 60 * 1000 });

  return (
    <Fragment>
      <Modal
        centered
        className="modal-template"
        isOpen={idleTimeoutModalOpen && isAuthenticated}
        toggle={() => {
          idleTimer.activate();
        }}
      >
        <ModalHeader>Session Timeout</ModalHeader>
        <ModalBody>
          You're being timed out due to inactivity. Please choose to stay signed in or to logoff.
          Otherwise, you will be logged off automatically.
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              idleTimer.activate();
            }}
          >
            Continue
          </Button>{' '}
          <Button color="primary" onClick={idleOut}>
            Sign Out
          </Button>{' '}
        </ModalFooter>
      </Modal>
      {isLoading && <LoadingIndicator />}
      <Layout>
        <Routes>
          <Route element={<Login />} path="/login" />
          <Route element={<About />} path="/about" />
          <Route element={<Privacy />} path="/Privacy" />
          <Route element={<TermsOfUse />} path="/termsofuse" />
          <Route element={<Support />} path="/support" />

          <Route element={<ResetPassword />} path="/reset-password" />

          <Route element={<SentEmail />} path="/sent-email" />

          <Route element={<EmailRedirect />} path="/email-redirect" />

          <Route
            element={<AuthRoute element={<SetupForm />} path="/setup-account" />}
            path="/setup-account"
          />

          <Route
            element={<AuthRoute element={<CreateNewPassword />} path="/create-password" />}
            path="/create-password"
          />

          <Route element={<AuthRoute element={<Account />} path="/account" />} path="/account" />

          <Route
            element={<AuthRoute element={<Campaigns />} path="/campaigns" />}
            path="/campaigns"
          />

          <Route
            element={<AuthRoute element={<AddCampaign />} path="/campaigns/add" />}
            path="/campaigns/add"
          />

          <Route
            element={<AuthRoute element={<CampaignDetail />} path="/campaigns/campaign/:id" />}
            path="/campaigns/campaign/:id"
          />

          <Route
            element={<AuthRoute element={<Tracebacks />} path="/tracebacks" />}
            path="/tracebacks"
          />
          <Route
            element={<AuthRoute element={<AddTraceback />} path="/tracebacks/add" />}
            path="/tracebacks/add"
          />
          <Route
            element={<AuthRoute element={<TracebackDetail />} path="/tracebacks/traceback/:id" />}
            path="/tracebacks/traceback/:id"
          />

          <Route
            element={<AuthRoute element={<Traceforwards />} path="/traceforwards" />}
            path="/traceforwards"
          />
          <Route
            element={<AuthRoute element={<TraceforwardAdd />} path="/traceforwards/add" />}
            path="/traceforwards/add"
          />
          <Route
            element={
              <AuthRoute element={<TraceforwardDetail />} path="/traceforwards/traceforward/:id" />
            }
            path="/traceforwards/traceforward/:id"
          />

          <Route
            element={<AuthRoute element={<UserDetail />} path="/users/user/:id" />}
            path="/users/user/:id"
          />

          <Route element={<AuthRoute element={<Hops />} path="/hops" />} path="/hops" />
          <Route
            element={<AuthRoute element={<Hops />} path="/hops/provider/:id" />}
            path="/hops/provider/:id"
          />
          <Route
            element={<AuthRoute element={<Hops />} path="/hops/traceback/:id" />}
            path="/hops/traceback/:id"
          />
          <Route
            element={<AuthRoute element={<HopDetail />} path="/hops/hop/:id" />}
            path="/hops/hop/:id"
          />

          <Route element={<AuthRoute element={<TfHops />} path="/tfhops" />} path="/tfhops" />
          <Route
            element={<AuthRoute element={<TfHopDetail />} path="/tfhops/tfhop/:id" />}
            path="/tfhops/tfhop/:id"
          />

          <Route
            element={<AuthRoute element={<Providers />} path="/providers" />}
            path="/providers"
          />

          <Route
            element={<AuthRoute element={<AddProvider />} path="/providers/add" />}
            path="/providers/add"
          />

          <Route
            element={<AuthRoute element={<ProviderDetail />} path="/providers/provider/:id" />}
            path="/providers/provider/:id"
          />

          <Route
            element={
              <AuthRoute element={<ProviderSummary />} path="/providers/provider/summaries/:id" />
            }
            path="/providers/provider/summaries/:id"
          />
          <Route
            element={
              <AuthRoute element={<ProviderInsight />} path="/providers/provider/insights/:id" />
            }
            path="/providers/provider/insights/:id"
          />

          <Route element={<AuthRoute element={<HomePage />} path="/" />} path="/" />

          <Route element={<AuthRoute element={<Comments />} path="/comments" />} path="/comments" />
          <Route
            element={<AuthRoute element={<CommentAdd />} path="/comments/add" />}
            path="/comments/add"
          />
          <Route
            element={<AuthRoute element={<TracebackCommentAdd />} path="/traceback-comments/add" />}
            path="/traceback-comments/add"
          />

          <Route
            element={<AuthRoute element={<GovCommentAdd />} path="/gov-comments/add" />}
            path="/gov-comments/add"
          />

          <Route
            element={<AuthRoute element={<CommentDetail />} path="/comments/comment/:id" />}
            path="/comments/comment/:id"
          />

          <Route
            element={<AuthRoute element={<AdminDashboard />} path="/admin-dashboard" />}
            path="/admin-dashboard"
          />

          <Route element={<AuthRoute element={<Partners />} path="/partners" />} path="/partners" />

          <Route
            element={<AuthRoute element={<AddEvent />} path="/partners/event/add" />}
            path="/partners/event/add"
          />

          <Route
            element={<AuthRoute element={<EditEvent />} path="/partners/event/:id" />}
            path="/partners/event/:id"
          />

          <Route
            element={<AuthRoute element={<DnoProviders />} path="/dno-providers" />}
            path="/dno-providers"
          />

          <Route
            element={<AuthRoute element={<ZendeskLogin />} path="/zendesk/token" />}
            path="/zendesk/token"
          />
          <Route
            element={<AuthRoute element={<ImportTracebacks />} path="/tracebacks/import" />}
            path="/tracebacks/import"
          />
          <Route
            element={<AuthRoute element={<SignOutElement />} path="/zendesk/logout" />}
            path="/zendesk/logout"
          />
          <Route
            element={<AuthRoute element={<ProvidersInfo />} path="/nrProviderInfo" />}
            path="/nrProviderInfo"
          />
          <Route
            element={<AuthRoute element={<ProvidersInfo />} path="/newProviderInfo" />}
            path="/newProviderInfo"
          />
          <Route
            element={<AuthRoute element={<ProvidersInfo />} path="/nfProviderInfo" />}
            path="/nfProviderInfo"
          />
          <Route
            element={<AuthRoute element={<NavRequest />} path="/requests" />}
            path="/requests"
          />
          <Route
            element={<AuthRoute element={<AddRequest />} path="/requests/add" />}
            path="/requests/add"
          />

          <Route element={<Default />} path="/404" />

          <Route path="*" element={<Default />} />
        </Routes>
      </Layout>
      <Footer />
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    user: sm.user,
    isAuthenticated: sm.isAuthenticated,
    isLoading: sm.loading,
    loadingTree: sm.loadingTree,
    campaign: sm.campaign.campaign,
    provider: sm.provider.provider
  };
};

const mapActionsToProps = {
  setupDataManager,
  staySignedIn,
  signOut,
  getProviderNameList
};

export default connect(mapStateToProps, mapActionsToProps)(App);
