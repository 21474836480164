import React, { FC, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import Breadcrumb from '../components/Breadcrumbs';
import CustomDataTable from '../components/CustomDataTable';
import { Pagination } from '../interfaces/pagination';
import {
  addOrEditEventColumns,
  addOrEditEventTfColumns,
  customCellStylesEvent,
  defaultPaginationParamsEvents
} from '../lib/utilities';
import {
  deleteEvent,
  getAllPartnerStats,
  getAllPartnerTfStats,
  resetErrorAndRedirect,
  updateEvent
} from '../redux/partners/thunks';
import { stateMappings } from '../redux/stateMappings';

interface IProps {
  redirectTo: string;
  error: string;
  partnerStats: any[];
  partnerTfStats: any[];
  getAllPartnerStats: Function;
  getAllPartnerTfStats: Function;
  updateEvent: Function;
  deleteEvent: Function;
  resetErrorAndRedirect: Function;
}

const rowSelected = (row: any) => row.eventId;

const EditEvent: FC<IProps> = ({
  redirectTo,
  error,
  partnerStats,
  partnerTfStats,
  getAllPartnerStats,
  getAllPartnerTfStats,
  updateEvent,
  deleteEvent,
  resetErrorAndRedirect
}) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [open, setOpen] = useState(false);
  const [paginationParams, setPaginationParams] = useState(defaultPaginationParamsEvents);
  const [tfPaginationParams, setTfPaginationParams] = useState(defaultPaginationParamsEvents);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectedTfRows, setSelectedTfRows] = useState<number[]>([]);

  useEffect(() => {
    setSelectedRows(partnerStats.filter(rowSelected).map((v) => v.tracebackId));
  }, [partnerStats]);

  useEffect(() => {
    setSelectedTfRows(partnerTfStats.filter(rowSelected).map((v) => v.traceforwardId));
  }, [partnerTfStats]);

  const handlePaginationChange = (params: Pagination) => {
    const newParams = { ...paginationParams, ...params };
    setPaginationParams(newParams);
  };
  const handleTfPaginationChange = (params: Pagination) => {
    const newParams = { ...tfPaginationParams, ...params };
    setTfPaginationParams(newParams);
  };

  const handleDeleteEvent = async () => {
    setOpen(false);
    await deleteEvent(id);
  };

  const handleUpdateEvent = () => {
    updateEvent(id, selectedRows, selectedTfRows);
  };

  useEffect(() => {
    resetErrorAndRedirect();
  }, []);

  useEffect(() => {
    getAllPartnerStats(id, paginationParams);
  }, [paginationParams]);
  useEffect(() => {
    getAllPartnerTfStats(id, tfPaginationParams);
  }, [tfPaginationParams]);

  useEffect(() => {
    if (redirectTo !== '') navigate(redirectTo);
    if (error !== '') navigate('/404');
  }, [redirectTo, error]);

  return (
    <Fragment>
      <Breadcrumb title="Partners" destination={'/partners'} detail="Edit Event" />
      <Card className="telecom-card">
        <CardHeader className="telecom-card-header">Edit Event {id || ''}</CardHeader>
        <CardBody className="justify-content-center px-0">
          <p className="ps-3">
            By selecting new tracebacks, they will be added to the current event. <br />
            By deselecting existing tracebacks binded to this event, they will be removed from it.
          </p>
          <div className="pt-4">
            <CustomDataTable
              tableData={partnerTfStats}
              columns={addOrEditEventTfColumns}
              defaultSortFieldId={'campaignName'}
              defaultSortAsc
              updatePaginationParams={handleTfPaginationChange}
              selectableRow
              selectableRowSelected={rowSelected}
              onSelectedRowsChange={({ selectedRows: currentSelected }) => {
                setSelectedTfRows(currentSelected.map((entry) => entry.traceforwardId));
              }}
              selectableRowsHighlight
              customStyles={customCellStylesEvent}
            />
          </div>
          <div className="pt-4">
            <CustomDataTable
              tableData={partnerStats}
              columns={addOrEditEventColumns}
              defaultSortFieldId={'campaignName'}
              defaultSortAsc
              updatePaginationParams={handlePaginationChange}
              selectableRow
              selectableRowSelected={rowSelected}
              onSelectedRowsChange={({ selectedRows: currentSelected }) => {
                setSelectedRows(currentSelected.map((entry) => entry.tracebackId));
              }}
              selectableRowsHighlight
              customStyles={customCellStylesEvent}
            />
          </div>
          {((partnerStats && !!partnerStats.length) ||
            (partnerTfStats && !!partnerTfStats.length)) && (
            <div className="d-flex justify-content-center" style={{ gap: '12px' }}>
              <Button
                color="danger"
                className="mt-4"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Delete Event
              </Button>
              <Button color="primary" className="mt-4" onClick={handleUpdateEvent}>
                Save Updates
              </Button>
            </div>
          )}
        </CardBody>
      </Card>

      <Modal
        centered
        isOpen={open}
        className="submit-confirm-modal"
        toggle={() => setOpen((prev) => !prev)}
      >
        <ModalHeader toggle={() => setOpen((prev) => !prev)}>Confirm delete !</ModalHeader>
        <ModalBody>
          Are you sure that you want to delete this event ? <br />
          This action is permanent and it cannot be undone !
        </ModalBody>
        <ModalFooter style={{ margin: 'auto' }}>
          <div style={{ gap: '12px' }}>
            <Button
              className="btn-default telecom-btn"
              color="light"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button
              className="telecom-btn"
              style={{ background: '#d61827' }}
              onClick={() => handleDeleteEvent()}
            >
              Proceed
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    partnerStats: sm.partners.partnerStats,
    partnerTfStats: sm.partners.partnerTfStats,
    redirectTo: sm.partners.redirectTo,
    error: sm.partners.error
  };
};

const mapActionsToProps = {
  getAllPartnerStats,
  getAllPartnerTfStats,
  updateEvent,
  deleteEvent,
  resetErrorAndRedirect
};

export default connect(mapStateToProps, mapActionsToProps)(EditEvent);
