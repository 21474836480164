import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import Select from 'react-select';
import { Button, CardBody, FormGroup, Label } from 'reactstrap';
import { SelectOption } from '../components/CustomSelect';
import ProvidersSelect from '../components/ProvidersSelect';
import InputError from '../components/inputError';
import InputFormGroup from '../components/inputFormGroup';
import RequestorPopover from '../components/shared/RequestorPopover';
import { userTypes } from '../enum/userTypes';
import { CreateAdminPostData, PersonalInfo } from '../interfaces/user';
import { validEmailRegex } from '../lib/regex';
import { removeEmailFromSESListApi } from '../redux/messaging/apiCalls';
import { editUserApiCall } from '../redux/user/apiCalls';
import { isValidUsername } from '../lib/utilities';

const roles = [
  { label: 'DNO', value: userTypes.DnoUser },
  { label: 'Partner', value: userTypes.PartnerUser },
  { label: 'Provider', value: userTypes.Provider },
  { label: 'Provider Manager', value: userTypes.ProviderManager },
  { label: 'Gov', value: userTypes.GovUser },
  { label: 'Admin', value: userTypes.Admin }
];

interface IProps {
  user: PersonalInfo;
  id: any;
}

const UserEditor: FC<IProps> = ({ user, id }) => {
  const navigate = useNavigate();

  const userId = parseInt(id);

  const [name, setName] = useState(user.name);
  const [nameError, setNameError] = useState('');
  const [email, setEmail] = useState(user.email);
  const [emailError, setEmailError] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber);
  const [roleType, setRoleType] = useState<number>(user.roleType);
  const [providerId, setProviderId] = useState<number>(
    roleType === userTypes.Admin ? 0 : user.providerId
  );
  const [providerError, setProviderError] = useState('');
  const [isTBEmail, setISTBEmal] = useState(user.isTBEmail);
  const [isEscalationEmail, setIsEscalationEmail] = useState(user.isEscalationEmail);
  const [isActive, setIsActive] = useState(user.isActive);
  const [allowAutomationAPIs, setAllowAutomationAPIs] = useState<boolean>(user.allowAutomationAPIs);
  const [requestors, setRequestors] = useState<SelectOption[]>(
    (user.requestors || []).map((v) => ({ value: v.requestorId, label: v.requestorName }))
  );

  const handleChange = (e: any, option: string) => {
    switch (option) {
      case 'name':
        setName(e.value);
        break;
      case 'email':
        setEmail(e.value);
        break;
      case 'phoneNumber':
        setPhoneNumber(e.value);
        break;
      case 'roleType':
        setRoleType(e.value);
        break;
      case 'selectedProvider':
        setProviderId(e.value);
        break;
      case 'tracebackEmails':
        setISTBEmal(!isTBEmail);
        break;
      case 'escalationEmails':
        setIsEscalationEmail(!isEscalationEmail);
        break;
      case 'active':
        setIsActive(!isActive);
        break;
      case 'swagger':
        setAllowAutomationAPIs(!allowAutomationAPIs);
        break;
    }
  };

  const isSubmit = useMemo(
    () =>
      name &&
      isValidUsername(name) &&
      email !== '' &&
      email.match(validEmailRegex) &&
      !(roleType === userTypes.PartnerUser && (!requestors || requestors.length === 0)) &&
      !(
        (roleType === userTypes.Provider || roleType === userTypes.ProviderManager) &&
        providerId === 0
      ),
    [name, email, roleType, requestors, providerId]
  );

  useEffect(() => {
    if (email !== '') {
      setEmailError(!email.match(validEmailRegex) ? 'This is not a valid email address !' : '');
    }
  }, [email]);

  useEffect(() => {
    if (roleType === userTypes.GovUser) {
      setISTBEmal(true);
      setIsEscalationEmail(true);
    }
  }, [roleType]);

  const EditUser = async () => {
    if (
      (roleType === userTypes.Provider || roleType === userTypes.ProviderManager) &&
      providerId === 0
    ) {
      setProviderError('You must select a provider ');
    } else {
      setProviderError('');
    }

    if (!name || !isValidUsername(name)) {
      setNameError('Please provide a valid name!');
    }

    if (email === '') {
      setEmailError('Please provide an email !');
    }

    if (isSubmit) {
      const userToSubmit: CreateAdminPostData = {
        name,
        userName: email,
        email: email,
        isTBEmail,
        isEscalationEmail,
        phoneNumber,
        providerId,
        roleType,
        isActive,
        requestorIds: requestors.map((v) => v.value),
        allowAutomationAPIs: roleType == userTypes.Admin || allowAutomationAPIs,
        status: 0
      };

      try {
        await editUserApiCall(userId, userToSubmit);
        navigate('/admin-dashboard#users');
      } catch (error: any) {
        setEmailError('This email address is already in use ');
      }
    }
    return;
  };

  const cancel = async () => {
    if (user.status === 1) {
      try {
        const userToSubmit: CreateAdminPostData = {
          name,
          userName: email,
          email: email,
          isTBEmail: false,
          isEscalationEmail: false,
          phoneNumber,
          providerId,
          roleType,
          isActive: false,
          requestorIds: [],
          allowAutomationAPIs: false,
          status: 2
        };
        await editUserApiCall(userId, userToSubmit);
        navigate('/admin-dashboard#users');
      } catch (error) {}
    } else {
      navigate('/admin-dashboard#users');
    }
  };

  return (
    <>
      {user.status === 2 ? (
        <Fragment>
          <div className="text-center mt-2">
            <h1>User Rejected</h1>
            <p>{user.email} has already been rejected.</p>
          </div>
        </Fragment>
      ) : (
        <>
          {user.status === 1 && (
            <>
              <Label className="traceback-heading me-0 mb-2" style={{ fontSize: '36px' }}>
                Approve New User
              </Label>
              <p>
                An updated RMD entry for {user.providerName} includes a new contact. Make changes if
                needed and 'Submit' to confirm addition.
              </p>
            </>
          )}
          <CardBody>
            <FormGroup
              style={{ marginLeft: 'auto', marginRight: 'auto' }}
              className="col-md-5 mb-0"
            >
              <h5 className="col-md-12 p-0 traceback-sub-title">Personal Details</h5>
              <div className="d-flex flex-row">
                <div className="d-flex flex-column col-6">
                  <label className="label-bold">
                    Name
                    <i className="fa fa-asterisk asterisk" />
                    <InputFormGroup
                      inputName="name"
                      inputId="name"
                      inputClassName="input-traceback"
                      inputAutoComplete="off"
                      inputValue={name}
                      inputOnChange={(e) => handleChange(e.target, 'name')}
                      errorMessage={nameError}
                    />
                  </label>
                </div>
                <div className="d-flex flex-column col-6 ms-2">
                  <label className="label-bold">
                    Email
                    <i className="fa fa-asterisk asterisk" />
                    <InputFormGroup
                      inputName="email"
                      inputId="email"
                      inputClassName="input-traceback"
                      inputAutoComplete="off"
                      inputValue={email}
                      inputOnChange={(e) => handleChange(e.target, 'email')}
                      errorMessage={emailError}
                    />
                  </label>
                </div>
              </div>

              <div className="d-flex flex-row">
                <div className="d-flex flex-column col-6">
                  <label className="label-bold">
                    Phone number
                    <InputFormGroup
                      inputName="phoneNumber"
                      inputId="phoneNumber"
                      inputClassName="input-traceback"
                      inputAutoComplete="off"
                      inputValue={phoneNumber.toString()}
                      inputOnChange={(e) => handleChange(e.target, 'phoneNumber')}
                    />
                  </label>
                </div>
              </div>
            </FormGroup>
            <FormGroup
              style={{ marginLeft: 'auto', marginRight: 'auto' }}
              className="col-md-5 mb-0"
            >
              <h5 className="col-md-12 p-0 traceback-sub-title">Role & Provider</h5>
              <div className="d-flex flex-row">
                <div className="col-6 me-2">
                  <label className="label-bold">
                    Role
                    <i className="fa fa-asterisk asterisk" />
                    <Select
                      placeholder="Select Role  "
                      classNamePrefix="customselect"
                      className="customselect-large"
                      options={roles}
                      value={roles.find((item) => item.value === roleType)}
                      onChange={(e) => handleChange(e, 'roleType')}
                      isSearchable={false}
                    />
                  </label>
                </div>
                {roleType === userTypes.Provider || roleType === userTypes.ProviderManager ? (
                  <div className="col-6">
                    <label className="label-bold">
                      Provider name
                      <i className="fa fa-asterisk asterisk" />
                      <ProvidersSelect
                        option={providerId || user.providerId}
                        isSearchable
                        className="customselect-large"
                        onChange={(e) => handleChange(e, 'selectedProvider')}
                      />
                    </label>
                    <InputError className="telecom-input-error">{providerError}</InputError>
                  </div>
                ) : null}
              </div>
            </FormGroup>
            {roleType !== userTypes.Admin && (
              <FormGroup className="col-md-5 mb-0 mt-3 me-auto ms-auto">
                <h5 className="col-md-12 p-0 traceback-sub-title">API</h5>
                <div className="d-flex flex-row">
                  <div className="col-6">
                    <input
                      style={{ width: '20px', height: '20px', marginTop: '5px' }}
                      type="checkbox"
                      checked={allowAutomationAPIs}
                      onChange={(e) => handleChange(e, 'swagger')}
                    />
                    <label style={{ position: 'absolute', paddingTop: 5 }} className="ps-2 pb-2">
                      API Automation and Swagger Documentation
                    </label>
                  </div>
                </div>
              </FormGroup>
            )}
            {roleType === userTypes.GovUser ||
            roleType === userTypes.DnoUser ||
            roleType === userTypes.PartnerUser ? null : (
              <FormGroup className="col-md-5 mb-0 mt-3 me-auto ms-auto">
                <h5 className="col-md-12 p-0 traceback-sub-title">Email Notifications</h5>
                <div className="d-flex flex-row">
                  <div className="col-6">
                    <input
                      style={{ width: '20px', height: '20px', marginTop: '5px' }}
                      type="checkbox"
                      checked={isTBEmail}
                      onChange={(e) => handleChange(e, 'tracebackEmails')}
                    />
                    <label style={{ position: 'absolute', paddingTop: 5 }} className="ps-2 pb-2">
                      Traceback Emails
                    </label>
                  </div>

                  <div className="col-6">
                    <input
                      style={{ width: '20px', height: '20px', marginTop: '5px' }}
                      type="checkbox"
                      checked={isEscalationEmail}
                      onChange={(e) => handleChange(e, 'escalationEmails')}
                    />
                    <label style={{ position: 'absolute', paddingTop: 5 }} className="ps-2 pb-2">
                      Escalation Emails
                    </label>
                  </div>
                </div>
              </FormGroup>
            )}
            <FormGroup className="col-md-5 mb-0 mt-3 ms-auto me-auto">
              <h5 className="col-md-12 p-0 traceback-sub-title">User requestors</h5>
              <div className="d-flex flex-row">
                <div className="col-6">
                  <RequestorPopover
                    values={requestors}
                    setValues={setRequestors}
                    radioType={false}
                    popoverHeaderMessage="Select one or more requestors"
                  />
                  {roleType === userTypes.PartnerUser &&
                    (!requestors || requestors.length === 0) && (
                      <InputError>
                        Partners should have at least one requestor associated
                      </InputError>
                    )}
                </div>
              </div>
            </FormGroup>
            <FormGroup className="col-md-5 mb-0 mt-3 me-auto ms-auto ">
              <h5 className="col-md-12 p-0 traceback-sub-title">User activity</h5>
              <div className="d-flex flex-row">
                <div className="col-6">
                  <input
                    style={{ width: 20, height: 20, marginTop: 5 }}
                    type="checkbox"
                    checked={isActive}
                    onChange={(e) => handleChange(e, 'active')}
                  />
                  <label style={{ position: 'absolute', paddingTop: 5 }} className="ps-2 pb-2">
                    Active
                  </label>
                </div>
              </div>
            </FormGroup>
            <FormGroup className="col-md-5 mb-0 mt-3 me-auto ms-auto ">
              <h5 className="col-md-12 p-0 traceback-sub-title">Email Issues</h5>
              {!!user.emailValidation?.aws_reason && (
                <div className="d-flex flex-row">
                  <div className="col-3">SES Blocked</div>
                  <div className="col-9">
                    <Button
                      className="telecom-btn ms-4"
                      onClick={() => {
                        try {
                          removeEmailFromSESListApi(user.email);
                        } catch (e) {}
                      }}
                    >
                      Remove From SES Block List
                    </Button>
                  </div>
                </div>
              )}
              <div className="d-flex flex-row">
                <div className="col-6">Zerobounce status:</div>
                <div className="col-6">{user.emailValidation?.status}</div>
              </div>
            </FormGroup>
            <div style={{ textAlign: 'center', marginTop: '100px' }}>
              <Button
                className="btn-default telecom-btn"
                color="light"
                onClick={async () => await cancel()}
              >
                Cancel
              </Button>
              <Button className="telecom-btn ms-4" onClick={() => EditUser()}>
                Save
              </Button>
            </div>
          </CardBody>
        </>
      )}
    </>
  );
};

export default UserEditor;
