import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import Select from 'react-select';
import { Button, CardBody, Form, FormGroup, Label } from 'reactstrap';
import { userTypes } from '../../../enum/userTypes';
import { validatePhoneNumber } from '../../../lib/phoneNumber';
import { validEmailRegex } from '../../../lib/regex';
import { addUserApiCall } from '../../../redux/user/apiCalls';
import { SelectOption } from '../../CustomSelect';
import ProvidersSelect from '../../ProvidersSelect';
import InputError from '../../inputError';
import InputFormGroup from '../../inputFormGroup';
import RequestorPopover from '../../shared/RequestorPopover';
import { isValidUsername } from '../../../lib/utilities';

const roles = [
  { label: 'DNO', value: userTypes.DnoUser },
  { label: 'Partner', value: userTypes.PartnerUser },
  { label: 'Provider', value: userTypes.Provider },
  { label: 'Provider Manager', value: userTypes.ProviderManager },
  { label: 'Gov', value: userTypes.GovUser },
  { label: 'Admin', value: userTypes.Admin }
];

const UserAdd = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  useEffect(() => {
    setEmailError(
      email && !email.match(validEmailRegex) ? 'This is not a valid email address !' : ''
    );
  }, [email]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const phoneError = useMemo(
    () =>
      phoneNumber !== '' && !validatePhoneNumber(phoneNumber)
        ? 'Please provide a valid phone number !'
        : '',
    [phoneNumber]
  );
  const [selectedProvider, setSelectedProvider] = useState<number>(0);
  const [role, setRole] = useState<number>(0);
  const [tracebackEmails, setTracebackEmails] = useState(false);
  const [escalationEmails, setEscalationEmails] = useState(false);
  const [active, setActive] = useState(true);
  const [allowAutomationAPIs, setAllowAutomationAPIs] = useState<boolean>(false);
  const [requestors, setRequestors] = useState<SelectOption[]>([]);
  const isSubmit = useMemo(
    () =>
      name &&
      isValidUsername(name) &&
      email !== '' &&
      email.match(validEmailRegex) &&
      role != 0 &&
      ((role !== userTypes.Provider && role !== userTypes.ProviderManager) ||
        selectedProvider !== 0) &&
      (!phoneNumber || validatePhoneNumber(phoneNumber)) &&
      !(role === userTypes.PartnerUser && (!requestors || requestors.length === 0)),
    [name, email, role, selectedProvider, phoneNumber, requestors]
  );

  const navigate = useNavigate();

  const handleChange = (option: any, key: string) => {
    switch (key) {
      case 'name':
        setName(option.value);
        break;
      case 'email':
        setEmail(option.value);
        break;
      case 'phoneNumber':
        setPhoneNumber(option.value);
        break;
      case 'role':
        setRole(option.value);
        break;
      case 'selectedProvider':
        setSelectedProvider(option.value);
        break;
      case 'tracebackEmails':
        setTracebackEmails(!tracebackEmails);
        break;
      case 'escalationEmails':
        setEscalationEmails(!escalationEmails);
        break;
      case 'active':
        setActive(!active);
        break;
      case 'swagger':
        setAllowAutomationAPIs(!allowAutomationAPIs);
        break;
    }
  };

  useEffect(() => {
    if (
      role === userTypes.GovUser ||
      role === userTypes.DnoUser ||
      role === userTypes.PartnerUser
    ) {
      setTracebackEmails(true);
      setEscalationEmails(true);
    }
  }, [role]);

  const userSubmit = async () => {
    if (!isSubmit) return;
    try {
      await addUserApiCall({
        name,
        email,
        phoneNumber,
        roleType: role,
        providerId: selectedProvider,
        isTBEmail: tracebackEmails,
        isEscalationEmail: escalationEmails,
        isActive: active,
        requestorIds: requestors.map((v) => v.value),
        allowAutomationAPIs: role == userTypes.Admin || allowAutomationAPIs
      });

      navigate('/admin-dashboard#users');
    } catch (error: any) {
      setEmailError('This user already exists');
    }
  };

  return (
    <CardBody>
      <Label className="traceback-heading mb-3" style={{ fontSize: '36px' }}>
        Add a User
      </Label>
      <Form className="form-traceback traceback-add">
        <FormGroup style={{ marginLeft: 'auto', marginRight: 'auto' }} className="col-md-5 mb-0">
          <h5 className="col-md-12 p-0 traceback-sub-title">Personal Details</h5>
          <div className="d-flex flex-row">
            <div className="d-flex flex-column col-6">
              <label className="label-bold">
                Name
                <i className="fa fa-asterisk asterisk" />
                <InputFormGroup
                  inputName="name"
                  inputId="name"
                  inputClassName="input-traceback"
                  inputAutoComplete="off"
                  inputOnChange={(option) => handleChange(option.target, 'name')}
                  inputValue={name}
                />
              </label>
            </div>
            <div className="d-flex flex-column col-6 ms-2">
              <label className="label-bold">
                Email
                <i className="fa fa-asterisk asterisk" />
                <InputFormGroup
                  inputName="email"
                  inputId="email"
                  inputClassName="input-traceback"
                  inputAutoComplete="off"
                  inputOnChange={(option) => handleChange(option.target, 'email')}
                  inputValue={email}
                  errorMessage={emailError}
                />
              </label>
            </div>
          </div>

          <div className="d-flex flex-row">
            <div className="d-flex flex-column col-6">
              <label className="label-bold">
                Phone number
                <InputFormGroup
                  inputName="phoneNumber"
                  inputId="phoneNumber"
                  inputClassName="input-traceback"
                  inputAutoComplete="off"
                  inputOnChange={(option) => handleChange(option.target, 'phoneNumber')}
                  inputValue={phoneNumber}
                  errorMessage={phoneError}
                />
              </label>
            </div>
          </div>
        </FormGroup>
        <FormGroup style={{ marginLeft: 'auto', marginRight: 'auto' }} className="col-md-5 mb-0">
          <h5 className="col-md-12 p-0 traceback-sub-title">Company</h5>
          <div className="d-flex flex-row">
            <div className="d-flex flex-column col-6">
              <label className="label-bold">
                Role
                <i className="fa fa-asterisk asterisk" />
                <Select
                  placeholder="Select Role  "
                  classNamePrefix="customselect"
                  className="customselect-large"
                  options={roles}
                  onChange={(option) => handleChange(option, 'role')}
                />
              </label>
            </div>
            {role === userTypes.Provider || role === userTypes.ProviderManager ? (
              <div className="d-flex flex-column col-6 ms-2">
                <label className="label-bold">
                  Provider name
                  <i className="fa fa-asterisk asterisk" />
                  <ProvidersSelect
                    option={selectedProvider}
                    isSearchable
                    className="customselect-large"
                    onChange={(option) => handleChange(option, 'selectedProvider')}
                  />
                </label>
                <span
                  className="span-cell blue"
                  onClick={() => navigate('/providers/add')}
                  style={{ cursor: 'pointer' }}
                >
                  + Add New Provider
                </span>
              </div>
            ) : null}
          </div>
        </FormGroup>
        {role && role !== userTypes.Admin && (
          <FormGroup className="col-md-5 mb-0 mt-3 me-auto ms-auto">
            <h5 className="col-md-12 p-0 traceback-sub-title">API</h5>
            <div className="d-flex flex-row">
              <div className="col-6">
                <input
                  style={{ width: '20px', height: '20px', marginTop: '5px' }}
                  type="checkbox"
                  checked={allowAutomationAPIs}
                  onChange={(e) => handleChange(e, 'swagger')}
                />
                <label style={{ position: 'absolute', paddingTop: 5 }} className="ps-2 pb-2">
                  API Automation and Swagger Documentation
                </label>
              </div>
            </div>
          </FormGroup>
        )}
        {role !== userTypes.GovUser &&
          role !== userTypes.DnoUser &&
          role !== userTypes.PartnerUser && (
            <FormGroup className="col-md-5 mb-0 mt-3 ms-auto me-auto">
              <h5 className="col-md-12 p-0 traceback-sub-title">Email Notifications</h5>
              <div className="d-flex flex-row">
                <div className="col-6">
                  <input
                    onChange={(option) => handleChange(option, 'tracebackEmails')}
                    style={{ width: '20px', height: '20px', marginTop: '5px' }}
                    type="checkbox"
                    checked={tracebackEmails}
                  />
                  <label style={{ position: 'absolute', paddingTop: 5 }} className="ps-2 pb-2">
                    Traceback Emails
                  </label>
                </div>

                <div className="col-6">
                  <input
                    onChange={(option) => handleChange(option, 'escalationEmails')}
                    style={{ width: '20px', height: '20px', marginTop: '5px' }}
                    type="checkbox"
                    checked={escalationEmails}
                  />
                  <label style={{ position: 'absolute', paddingTop: 5 }} className="ps-2 pb-2">
                    Escalation Emails
                  </label>
                </div>
              </div>
            </FormGroup>
          )}
        <FormGroup className="col-md-5 mb-0 mt-3 ms-auto me-auto">
          <h5 className="col-md-12 p-0 traceback-sub-title">User requestors</h5>
          <div className="d-flex flex-row">
            <div className="col-6">
              <RequestorPopover
                values={requestors}
                setValues={setRequestors}
                radioType={false}
                popoverHeaderMessage="Select one or more requestors"
              />
              {role === userTypes.PartnerUser && (!requestors || requestors.length === 0) && (
                <InputError>Partners should have at least one requestor associated</InputError>
              )}
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-md-5 mb-0 mt-3 ms-auto me-auto">
          <h5 className="col-md-12 p-0 traceback-sub-title">User activity</h5>
          <div className="d-flex flex-row">
            <div className="col-6">
              <input
                onChange={(option) => handleChange(option, 'active')}
                style={{ width: 20, height: 20, marginTop: 5 }}
                type="checkbox"
                checked={active}
              />
              <label style={{ position: 'absolute', paddingTop: '5px' }} className="ps-2 pb-2">
                Active
              </label>
            </div>
          </div>
        </FormGroup>
        <div style={{ textAlign: 'center', marginTop: '100px' }}>
          <Button
            className="btn-default telecom-btn"
            color="light"
            onClick={() => navigate('/admin-dashboard#users')}
          >
            Cancel
          </Button>
          <Button
            disabled={!isSubmit}
            className="ms-4 telecom-btn"
            onClick={() => {
              userSubmit();
            }}
          >
            Submit
          </Button>
        </div>
      </Form>
    </CardBody>
  );
};

export default UserAdd;
