import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { CallSource, NotProvidedReason, emptyCallSource } from '../../interfaces/callSource';
import { NewHopProvider, emptyNewHopProvider } from '../../interfaces/hop';
import { RMDEntry } from '../../interfaces/rmd';
import { TfHop } from '../../interfaces/tfhop';
import { PersonalInfo } from '../../interfaces/user';
import { useValidator } from '../../lib/hooks';
import { addCommentObject } from '../../redux/comment/thunks';
import { getProviderIdForEmail } from '../../redux/hop/apiCalls';
import { getProviderNameList } from '../../redux/provider/thunks';
import { stateMappings } from '../../redux/stateMappings';
import { getProviderInSequenceStatusApiCall } from '../../redux/tfhop/apiCalls';
import { GetZeroBounceError } from '../NewProviderPopup';
import { ProviderItem } from '../ProvidersSelect';
import RmdSearcher from '../RmdSearcher';
import CallDestinationCustomerStep, {
  customerValidator
} from './FormSteps/CallDestinationCustomerStep';
import CallRecordNotProvidedStep, {
  callRecordNotProvidedValidator
} from './FormSteps/CallRecordNotProvidedStep';
import DefaultStep, { steps } from './FormSteps/DefaultStep';
import DownstreamProviderStep, { downstreamValidator } from './FormSteps/DownstreamProviderStep';
import NewProviderStep, {
  GetZeroBounceErrors,
  ZerobounceErrors,
  newProviderValidator
} from './FormSteps/NewProviderStep';

interface IProps {
  tfhop: TfHop;
  user: PersonalInfo;
  updateTfHopObject: Function;
  providersData: ProviderItem[];
  getProviderNameList: Function;

  //   campaignAttribute: CampaignAttributes;
}

const CallDestinationCard: React.FC<IProps> = ({
  tfhop,
  user,
  providersData,
  updateTfHopObject,
  getProviderNameList
}) => {
  const [step, setStep] = useState(steps.default);
  const [selectedCallDestination, setSelectedCallDestination] = useState(steps.default);
  const [isSubmit, setIsSubmit] = useState(false);
  const submitCallDestinationToggle = () => setIsSubmit((v) => !v);

  const [selectedProvider, setSelectedProvider] = useState(0);
  const providerData = providersData.find((v) => v.id === selectedProvider);
  const [extraContactEmail, setExtraContactEmail] = useState('');
  const [extraCommentRmd, setExtraCommentRmd] = useState('');
  const [extraContactEmailError, setExtraContactEmailError] = useState('');
  const [validateDownstreamErrors, clearDownstreamErrors] = useValidator(downstreamValidator);
  const [providerAlreadyInSequenceModal, setProviderAlreadyInSequenceModal] = useState(false);
  const providerAlreadyInSequenceToggle = () => setProviderAlreadyInSequenceModal((v) => !v);
  const submitAlreadyExistingPreviousProviderHop = () => {
    submitCallDestinationToggle();
    providerAlreadyInSequenceToggle();
  };

  const [callDestination, setCallDestination] = useState<CallSource>(emptyCallSource());
  const [validateCallDestinationErrors, clearCallDestinationErrors] =
    useValidator(customerValidator);
  const [addressModal, setAddressModal] = useState(false);
  const addressModalToggle = () => setAddressModal((v) => !v);
  const handleReasonReject = (e: string) =>
    setCallDestination((v) => ({ ...v, reasonRejection: e }));
  const [validateCallRecordErrors, clearCallRecordErrors] = useValidator(
    callRecordNotProvidedValidator
  );

  const [rmdBusinessName, setRmdBusinessName] = useState('');
  const [newProvider, setNewProvider] = useState(emptyNewHopProvider());
  const [validateNewProviderErrors, clearNewProviderErrors] = useValidator(newProviderValidator);
  const [zeroBounceErrors, setZeroBounceErrors] = useState<ZerobounceErrors>({
    contactEmail: '',
    extraContactEmail: ''
  });
  const [similarProviders, setSimilarProviders] = useState<ProviderItem[]>([]);
  const [isSimilarProvidersModalOpen, setSimilarProvidersIsModalOpen] = useState(false);
  const toggleSimilarProvidersModal = () => setSimilarProvidersIsModalOpen((v) => !v);

  const [customerEmailModal, setCustomerEmailModal] = useState(false);
  const [customerEmailConfirmation, setCustomerEmailConfirmation] = useState(false);
  const [customerEmailProvider, setCustomerEmailProvider] = useState<ProviderItem>();
  const toggleCustomerEmailModal = () => {
    setCustomerEmailModal((v) => !v);
  };

  const zeroBounceCheckEmail = async (email: string, isExtra?: boolean) => {
    const response = await GetZeroBounceErrors(email, isExtra);
    setZeroBounceErrors((v) => ({
      contactEmail: isExtra ? v.contactEmail : response,
      extraContactEmail: isExtra ? response : v.extraContactEmail
    }));
    return response;
  };

  useEffect(() => {
    getProviderNameList();
  }, []);

  const handleCallDestinationInputChange = (e: any) => {
    if (e !== null && e.currentTarget) {
      const { name, value } = e.currentTarget;
      switch (name) {
        case 'extraContactEmail':
          setExtraContactEmail(value);
          break;
        case 'extraCommentRmd':
          setExtraCommentRmd(value);
          break;
        default:
          break;
      }
    }
  };

  const next = async () => {
    switch (step) {
      case steps.default:
        setStep(selectedCallDestination);
        break;
      case steps.rmdSearch:
        setStep(steps.newProvider);
        break;
      case steps.callRecordNotProvided:
        const errors = validateCallRecordErrors(callDestination, true);
        if (Object.keys(errors).some((v) => !!errors[v])) return;
        submitCallDestinationToggle();
        break;
      case steps.callDestination: {
        if (!customerEmailConfirmation) {
          try {
            const rsp = await getProviderIdForEmail(callDestination.contactEmail);
            if (rsp.data) {
              const provider = providersData.find((v) => v.id === rsp.data);
              setCustomerEmailProvider(provider);
              setCustomerEmailModal(!!provider);
              if (!!provider) {
                return;
              }
            }
          } catch (error: any) {
            console.log('Error:', error);
          }
        }
        const errors = validateCallDestinationErrors(callDestination, true);
        if (Object.keys(errors).some((v) => !!errors[v])) return;
        addressModalToggle();
        break;
      }
      case steps.downstream: {
        const errors = validateDownstreamErrors(
          {
            selectedProvider,
            extraContactEmail,
            extraCommentRmd
          },
          true
        );

        if (
          !!errors.selectedProvider ||
          providersData.length === 0 ||
          (providerData?.badEmails && !!errors.extraContactEmail) ||
          (providerData?.noRmd &&
            user.providerCountry === 'United States' &&
            !!errors.extraCommentRmd)
        )
          return;
        try {
          if (providerData?.badEmails) {
            const zeroBounceError = await GetZeroBounceError(extraContactEmail);
            if (zeroBounceError) {
              setExtraContactEmailError(
                `The email has an issue - ${zeroBounceError}. Please provide a different email`
              );
              return;
            }
          }
          const providerAlreadyInSequence = await getProviderInSequenceStatusApiCall(
            tfhop.traceforwardId,
            selectedProvider
          );
          if (providerAlreadyInSequence.data.data) {
            providerAlreadyInSequenceToggle();
            return;
          }
        } catch (error: any) {
          console.log('Error:', error);
        }
        submitCallDestinationToggle();
        break;
      }
      case steps.newProvider: {
        const errors = validateNewProviderErrors(newProvider, true);
        if (
          !!errors.newProviderName ||
          !!errors.newProviderContactName ||
          !!errors.newProviderCountry ||
          !!errors.newProviderContactEmail ||
          (!!errors.acknowledge &&
            zeroBounceErrors.contactEmail &&
            !!errors.newProviderExtraContactEmail) ||
          (!!errors.acknowledge && !newProvider.newRmd)
        )
          return;
        if (
          !!newProvider.newRmd &&
          !!zeroBounceErrors.contactEmail &&
          (await zeroBounceCheckEmail(newProvider.newProviderExtraContactEmail, true))
        )
          return;
        else if (await zeroBounceCheckEmail(newProvider.newProviderContactEmail)) return;

        const filteredProviders = providersData.filter((providerElement: ProviderItem) =>
          providerElement.name.toLowerCase().includes(newProvider.newProviderName.toLowerCase())
        );

        if (filteredProviders && filteredProviders.length > 0) {
          setSimilarProviders(filteredProviders);
          toggleSimilarProvidersModal();
        } else submitCallDestinationToggle();
        break;
      }
      default:
    }
  };
  const back = () => {
    setSelectedCallDestination(steps.default);
    switch (step) {
      case steps.default:
        break;
      case steps.downstream:
        setSelectedProvider(0);
        setExtraCommentRmd('');
        setExtraContactEmail('');
        setExtraContactEmailError('');
        clearDownstreamErrors();
        setStep(steps.default);
        break;
      case steps.callDestination:
        setCallDestination(emptyCallSource());
        clearCallDestinationErrors();
        setStep(steps.default);
        break;
      case steps.callRecordNotProvided:
        setCallDestination(emptyCallSource());
        clearCallRecordErrors();
        setStep(steps.default);
        break;
      case steps.rmdSearch:
        setRmdBusinessName('');
        setStep(steps.downstream);
        break;
      case steps.newProvider:
        setNewProvider(emptyNewHopProvider());
        clearNewProviderErrors();
        setZeroBounceErrors({ contactEmail: '', extraContactEmail: '' });
        setRmdBusinessName('');
        setStep(steps.rmdSearch);
        break;
      default:
    }
  };

  const submitComments = async () => {
    if (
      (providerData?.noRmd && extraCommentRmd && user.providerCountry === 'United States') ||
      (providerData?.badEmails && extraContactEmail)
    ) {
      await addCommentObject(
        {
          commentType: 'tfhop',
          contentText:
            providerData?.noRmd &&
            providerData?.badEmails &&
            extraContactEmail &&
            extraCommentRmd &&
            user.providerCountry === 'United States'
              ? `Alternate contact for provider (${providerData.name}) is: ${extraContactEmail} and RMD info is: ${extraCommentRmd}`
              : !(providerData?.noRmd && user.providerCountry === 'United States') &&
                  providerData?.badEmails &&
                  extraContactEmail
                ? `An alternative email for the selected provider (${providerData.name}) is: ${extraContactEmail}`
                : providerData?.noRmd &&
                    !providerData?.badEmails &&
                    extraCommentRmd &&
                    user.providerCountry === 'United States'
                  ? `RMD info for provider (${providerData.name}) is: ${extraCommentRmd}`
                  : '',
          relatedObjectId: tfhop.tfhopId,
          directedTo: 'downstream'
        },
        []
      ).then(() => {
        setExtraContactEmail('');
      });
    }
  };

  const submitCallDestination = async () => {
    submitCallDestinationToggle();

    let hopToSubmit: any = {};

    if (step === steps.downstream) {
      hopToSubmit = {
        tfhopId: tfhop.tfhopId,
        downstreamProviderId: selectedProvider,
        delegatedTo: user.email,
        contactEmail:
          selectedProvider === customerEmailProvider?.id ? callDestination.contactEmail : ''
      };
    } else if (step === steps.newProvider) {
      hopToSubmit = {
        tfhopId: tfhop.tfhopId,
        delegatedTo: user.email,
        status: 6,
        newProvider: newProvider
      };
    } else if (step === steps.callDestination) {
      hopToSubmit = {
        tfhopId: tfhop.tfhopId,
        address: callDestination.addressLine1,
        city: callDestination.city,
        customer: callDestination.businessName,
        contactEmail: callDestination.contactEmail,
        contactPhone: callDestination.contactPhone,
        contactName: callDestination.contactName,
        country: callDestination.country,
        state: callDestination.region,
        zipCode: callDestination.postalCode,
        explanation: callDestination.stepsTaken,
        // isCallOrig: callOriginatorAndCallingParty,
        delegatedTo: user.email,
        // forwardedCall: forwardedCall,
        ipAddress: callDestination.ipAddress,
        customerType: callDestination.customerType,
        averageTraffic: callDestination.averageTraffic,
        actionTaken: callDestination.actionTaken,
        refuseReason: callDestination.reasonRejection
      };
      //   if (disputeInfo.disputeVisible) {
      //     const commentId = await addCommentObject(
      //       {
      //         commentType: 'hop',
      //         contentText: 'Dispute: ' + disputeInfo.comment,
      //         relatedObjectId: hop.hopId,
      //         directedTo: 'admin'
      //       },
      //       disputeInfo.attachments
      //     );
      //     if (commentId && Number(commentId)) {
      //       const dispute: HopDispute = {
      //         hopId: hop.hopId,
      //         status: DisputeStatus.OpenDispute,
      //         commentId,
      //         attributes: disputeInfo.attributes,
      //         consentGiven: disputeInfo.consentGiven
      //       };
      //       hopToSubmit.dispute = dispute;
      //     }
      //   }
    } else if (step === steps.callRecordNotProvided) {
      hopToSubmit = {
        tfhopId: tfhop.tfhopId,
        nfnr: callDestination.currentCause,
        nfnrReason: callDestination.explanation,
        delegatedTo: user.email
        // forwardedCall: forwardedCall
      };
    }

    await updateTfHopObject(hopToSubmit);
    if (step === steps.downstream) {
      await submitComments();
    }
    localStorage.setItem('currentCallSource', step);
  };
  return (
    <Col className="col-10 hop-detail-form-dark pb-3">
      {step === steps.default ? (
        <DefaultStep
          selectedCallDestination={selectedCallDestination}
          setSelectedCallDestination={setSelectedCallDestination}
        />
      ) : step === steps.downstream ? (
        <DownstreamProviderStep
          user={user}
          currentHopProviderId={tfhop.tfhopProvider.providerId}
          providerData={providerData}
          selectedProvider={selectedProvider}
          setSelectedProvider={setSelectedProvider}
          extraContactEmail={extraContactEmail}
          extraCommentRmd={extraCommentRmd}
          errors={validateDownstreamErrors({
            selectedProvider,
            extraContactEmail,
            extraCommentRmd
          })}
          zerobounceExtraContactEmailError={extraContactEmailError}
          handleCallDestinationInputChange={handleCallDestinationInputChange}
          newProvider={() => {
            setStep(steps.rmdSearch);
          }}
        />
      ) : step === steps.callRecordNotProvided ? (
        <CallRecordNotProvidedStep
          user={user}
          cause={callDestination.currentCause}
          setCause={(cause: NotProvidedReason) => {
            setCallDestination((v) => ({ ...v, currentCause: cause }));
          }}
          explanation={callDestination.explanation}
          setExplanation={(explanation: string) => {
            setCallDestination((v) => ({ ...v, explanation: explanation }));
          }}
          errors={validateCallRecordErrors(callDestination)}
        />
      ) : step === steps.callDestination ? (
        <CallDestinationCustomerStep
          callDestination={callDestination}
          setCallDestination={setCallDestination}
          errors={validateCallDestinationErrors(callDestination)}
        />
      ) : step === steps.rmdSearch ? (
        <RmdSearcher
          setBusinessName={setRmdBusinessName}
          setRmd={(rmd: RMDEntry) => {
            if (rmd && rmd.providerName && rmd.providerId) {
              setSelectedProvider(rmd.providerId);
              setStep(steps.downstream);
            } else {
              setNewProvider((v) => {
                const d: NewHopProvider = {
                  newProviderAddress: rmd.businessAddress || '',
                  newProviderCity: rmd.contactCity || '',
                  newProviderContactEmail: rmd.contactEmail || '',
                  newProviderContactName: rmd.robocallMitigationContactName || '',
                  newProviderContactPhone: rmd.contactTelephoneNumber || '',
                  newProviderCountry: (rmd.country || '').replace(' of America', '').trim(),
                  newProviderStateRegion: rmd.contactState || rmd.contactCity || '',
                  newProviderZip: rmd.contactZipCode || '',
                  newProviderName: rmd.businessName || '',
                  newProviderReason: '',
                  newProviderExtraContactEmail: '',
                  newRmd: rmd,
                  acknowledge: true
                };
                if (
                  !!validateNewProviderErrors(d).newProviderContactEmail &&
                  !!d.newProviderContactEmail
                )
                  zeroBounceCheckEmail(d.newProviderContactEmail);
                return d;
              });

              next();
            }
          }}
        />
      ) : step === steps.newProvider ? (
        <NewProviderStep
          newProvider={newProvider}
          setNewProvider={setNewProvider}
          errors={validateNewProviderErrors(newProvider)}
          extraErrors={zeroBounceErrors}
        />
      ) : (
        <></>
      )}
      <div className="d-flex justify-content-center">
        {step !== steps.default && (
          <Button className="btn-default telecom-btn" color="light" onClick={back}>
            Back
          </Button>
        )}
        <Button className="telecom-btn" onClick={next}>
          {step === steps.callRecordNotProvided ||
          step === steps.callDestination ||
          step === steps.downstream ||
          step === steps.newProvider
            ? 'Submit Traceforward Response'
            : 'Next'}
        </Button>
        {!!rmdBusinessName && step === steps.rmdSearch && (
          <button
            type="button"
            className="btn btn-link"
            style={{ marginLeft: '10px' }}
            onClick={() => {
              setNewProvider((v) => ({ ...v, newRmd: undefined }));
              next();
            }}
          >
            Manually enter a provider
          </button>
        )}
      </div>
      <Modal
        centered
        toggle={providerAlreadyInSequenceToggle}
        isOpen={providerAlreadyInSequenceModal}
        className="already-existing-modal"
      >
        <ModalHeader toggle={providerAlreadyInSequenceToggle}></ModalHeader>
        <ModalBody>
          <h5>This provider has already participated on an earlier hop in this traceback.</h5>
          <p className="text-center text-md-left mt-4 mb-n3">
            Are you sure you want to continue ?<br />
            Please send an email to{' '}
            <a href="mailto:support@tracebacks.org">support@tracebacks.org</a> if you believe there
            is an error.
          </p>
        </ModalBody>
        <ModalFooter style={{ margin: 'auto' }}>
          <Row>
            <Button
              className="btn-default telecom-btn"
              color="light"
              onClick={providerAlreadyInSequenceToggle}
            >
              Cancel
            </Button>
            <Button
              className="telecom-btn"
              onClick={() => submitAlreadyExistingPreviousProviderHop()}
            >
              Submit Anyway
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
      <Modal
        centered
        isOpen={isSubmit}
        className="submit-confirm-modal"
        toggle={submitCallDestinationToggle}
      >
        <ModalHeader toggle={submitCallDestinationToggle} />
        <ModalBody>
          <h5 className="traceback-sub-title">
            Once you update this hop, its status will no longer be editable. However, you may still
            submit comments.
          </h5>
          <h5 className="traceback-sub-title">Submit this form?</h5>
        </ModalBody>
        <ModalFooter style={{ margin: 'auto' }}>
          <Row>
            <Button
              className="btn-default telecom-btn"
              color="light"
              onClick={submitCallDestinationToggle}
            >
              Cancel
            </Button>
            <Button
              className="telecom-btn"
              style={{ background: '#d61827', textDecoration: 'none', color: 'white' }}
              onClick={() => {
                submitCallDestination();
              }}
            >
              Proceed
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
      <Modal
        centered
        isOpen={isSimilarProvidersModalOpen}
        className="already-existing-modal"
        toggle={toggleSimilarProvidersModal}
      >
        <ModalHeader toggle={toggleSimilarProvidersModal}>
          Similar provider/s already exist.
        </ModalHeader>
        <ModalBody>
          There is a list of all similar providers (click on any of the listed options in order to
          select the already existing provider):
          <ul style={{ margin: '24px auto' }}>
            {similarProviders &&
              React.Children.toArray(
                similarProviders.map((provider) => (
                  <li
                    className="similar-provider"
                    onClick={() => {
                      setSelectedProvider(provider.id);
                      setStep(steps.downstream);
                      setNewProvider(emptyNewHopProvider());
                      toggleSimilarProvidersModal();
                    }}
                  >
                    {provider.name}
                  </li>
                ))
              )}
          </ul>
        </ModalBody>
        <ModalFooter style={{ margin: 'auto' }}>
          <Row>
            <Button
              className="btn-default telecom-btn"
              color="light"
              onClick={toggleSimilarProvidersModal}
            >
              Cancel
            </Button>
            <Button
              className="telecom-btn"
              onClick={() => {
                toggleSimilarProvidersModal();
                submitCallDestinationToggle();
              }}
            >
              Submit anyway
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
      <Modal
        centered
        className="already-existing-modal"
        toggle={addressModalToggle}
        isOpen={addressModal}
      >
        <ModalHeader toggle={addressModalToggle}>
          <label className="text-center">Complete Address not provided !</label>
        </ModalHeader>
        <ModalBody>
          <div style={{ marginLeft: '20px' }}>
            <label className="label-bold">List of missing fields :</label>
            <ul style={{ padding: 20 }}>
              {callDestination.addressLine1 === '' ? <li>Address</li> : ''}
              {callDestination.city === '' ? <li>City</li> : ''}
              {callDestination.region === '' ? <li>Region</li> : ''}
              {callDestination.postalCode === '' ? <li>Zip/Postal Code</li> : ''}
              {callDestination.ipAddress === '0.0.0.0' ? (
                <li className="text-red">Calling Party IP Addresss: 0.0.0.0</li>
              ) : (
                ''
              )}
            </ul>
          </div>

          <div style={{ marginLeft: '20px', marginTop: '30px' }}>
            <div>
              <label className="label-bold">Choose one*</label>
            </div>

            <input
              type="radio"
              name="reason"
              onChange={() => handleReasonReject('do_not_collect')}
            />
            <span className="p-2">Do not collect</span>
            <div>
              <input
                type="radio"
                name="reason"
                onChange={() => handleReasonReject('unwilling_to_share')}
              />
              <span className="p-2">Unwilling to share</span>
            </div>
          </div>
        </ModalBody>
        <ModalFooter style={{ margin: 'auto' }}>
          <Row>
            <Button className="btn-default telecom-btn" color="light" onClick={addressModalToggle}>
              Cancel
            </Button>
            <Button
              disabled={callDestination.reasonRejection === ''}
              className="telecom-btn"
              onClick={() => {
                addressModalToggle();
                submitCallDestinationToggle();
              }}
            >
              Continue
            </Button>
          </Row>
        </ModalFooter>
      </Modal>{' '}
      <Modal
        centered
        className="modal-template mt-5 mb-5"
        size="lg"
        isOpen={customerEmailModal}
        toggle={toggleCustomerEmailModal}
      >
        <ModalHeader toggle={toggleCustomerEmailModal}>Customer matches provider</ModalHeader>
        <ModalBody>
          <p>
            It appears that the enduser/caller information matches with an existing Voice Service
            Provider,{' '}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setSelectedProvider(customerEmailProvider?.id);
                setStep(steps.downstream);
                toggleCustomerEmailModal();
              }}
            >
              {customerEmailProvider?.name}
            </a>
            . If this is correct please select them as your upstream. Otherwise press 'Submit As
            Enduser' to continue.
          </p>
        </ModalBody>
        <ModalFooter style={{ margin: 'auto' }}>
          <Button
            className="btn-default telecom-btn"
            color="light"
            onClick={() => {
              setCustomerEmailConfirmation(true);
              toggleCustomerEmailModal();
              next();
            }}
          >
            Submit As Enduser
          </Button>
          <Button
            className="telecom-btn"
            onClick={() => {
              setSelectedProvider(customerEmailProvider?.id);
              setStep(steps.downstream);
              toggleCustomerEmailModal();
            }}
          >
            Select as Upstream Provider
          </Button>
        </ModalFooter>
      </Modal>
    </Col>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    providersData: sm.provider.providerNames || []
  };
};

const mapActionsToProps = {
  getProviderNameList
};

export default connect(mapStateToProps, mapActionsToProps)(CallDestinationCard);
